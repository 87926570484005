import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiJava,
  DiHtml5,
  DiCss3,
  DiMysql
} from "react-icons/di";
import {
  SiFirebase,
} from "react-icons/si";
export default class Resume extends Component {
  render() {
    return <section id="resume">
        <div className="row education">
          <div className="three columns header-col">
            <h1>
              <span>Tech Stack</span>
            </h1>
          </div>

          <div className="techStack">
            <Row className="tech-icons-container">
              <Col className="tech-icons">
                <DiPython />
              </Col>
              <Col className="tech-icons">
                <CgCPlusPlus />
              </Col>
              <Col className="tech-icons">
                <DiHtml5 />
              </Col>
              <Col className="tech-icons">
                <DiCss3 />
              </Col>
              <Col className="tech-icons">
                <DiJavascript1 />
              </Col>
              <Col className="tech-icons">
                <DiMysql />
              </Col>
              <Col className="tech-icons">
                <DiNodejs />
              </Col>
              <Col className="tech-icons">
                <DiReact />
              </Col>
              <Col className="tech-icons">
                <DiMongodb />
              </Col>
              <Col className="tech-icons">
                <DiGit />
              </Col>
              <Col className="tech-icons">
                <SiFirebase />
              </Col>
              <Col className="tech-icons">
                <DiJava />
              </Col>
            </Row>
          </div>
        </div>
        {/* <div className="row work">
          <div className="three columns header-col">
            <h1>
              <span>Work</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            {resumeData.work && resumeData.work.map((item) => {
                return <div className="row item">
                    <div className="twelve columns">
                      <h3>{item.CompanyName}</h3>
                      <p className="info">
                        {item.specialization}
                        <span>&bull;</span> <em className="date">
                          {item.MonthOfLeaving} {item.YearOfLeaving}
                        </em>
                      </p>
                      <p>{item.Achievements}</p>
                    </div>
                  </div>;
              })}
          </div>
        </div> */}

        {/* <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span>Skills</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <p>{resumeData.skillsDescription}</p>

            <div className="bars">
              <ul className="skills">
                {resumeData.skills && resumeData.skills.map((item) => {
                    return <li>
                        <span className={`bar-expand ${item.skillname.toLowerCase()}`} />
                        <em>{item.skillname}</em>
                      </li>;
                  })}
              </ul>
            </div>
          </div>
        </div> */}
      </section>;
  }
}
