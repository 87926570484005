import { BsCalendar2Event, BsPersonWorkspace } from "react-icons/bs";
import { BiCameraMovie } from "react-icons/bi";
import React from "react";
let resumeData = {
  imagebaseurl: "https://rbhatia46.github.io/",
  name: "Sujal Gandhi",
  role: "Software Developer and Web Developer",
  linkedinId: "Your LinkedIn Id",
  skypeid: "Your skypeid",
  roleDescription:
    "I like dabbling in various parts of frontend development and like to learn about new technologies, write technical articles or simply play games in my free time.",
  socialLinks: [
    {
      id: 1,
      name: "linkedin",
      url: "https://www.linkedin.com/in/sujalgandhi/",
      className: "fa fa-linkedin",
    },
    {
      id: 2,
      name: "github",
      url: "http://github.com/Sujal366",
      className: "fa fa-github",
    },
    {
      id: 3,
      name: "instagram",
      url: "http://instagram.com/sujalgandhi63",
      className: "fa fa-instagram",
    },
  ],
  aboutme:
    "I am currently a third year student at TSEC and pursuing my B.Tech in Information Technology from here. I am a Web Dev Enthusiast, currently diving deeper into Machine Learning.",
  address: "India",
  website: "sujalgandhi786@gmail.com",
  education: [
    {
      UniversityName: "Thadomal Shahani Engineering College",
      specialization: "Information Technology",
      MonthOfPassing: "May",
      YearOfPassing: "2025",
      Achievements: "",
    },
    {
      UniversityName: "Some University",
      specialization: "Some specialization",
      MonthOfPassing: "Jan",
      YearOfPassing: "2018",
      Achievements: "Some Achievements",
    },
  ],
  work: [
    {
      CompanyName: "Some Company",
      specialization: "Some specialization",
      MonthOfLeaving: "Jan",
      YearOfLeaving: "2018",
      Achievements: "Some Achievements",
    },
    {
      CompanyName: "Some Company",
      specialization: "Some specialization",
      MonthOfLeaving: "Jan",
      YearOfLeaving: "2018",
      Achievements: "Some Achievements",
    },
  ],
  skillsDescription: "Your skills here",
  skills: [
    {
      skillname: "HTML5",
    },
    {
      skillname: "CSS",
    },
    {
      skillname: "Reactjs",
    },
  ],
  portfolio: [
    {
      id: 1,
      logo: <BsCalendar2Event />,
      name: "Event Spark",
      description:
        "An Event Management website that offers a seamless platform for event organizers to plan and execute exceptional events, while also providing an easy and efficient registration process for users. A project made on MERN stack.",
      imgurl: "images/portfolio/phone.jpg",
    },
    {
      id: 2,
      name: "Career Wise",
      logo: <BsPersonWorkspace />,
      description:
        "Utilizing an algorithm, personalized career advice is provided based on skills and interests. Using a custom algorithm to align test scores with ideal career choices, promoting informed decisions. Boosting user confidence with a real-time chatbot for career queries, enhancing understanding.",
      imgurl: "images/portfolio/project.jpg",
    },
    {
      id: 3,
      logo: <BiCameraMovie />,
      name: "Movie Ticket Booking System",
      description:
        "A simple cinema booking system implemented in Python using Tkinter for the graphical user interface (GUI) and MySQL for the database. Users can register, login, select movies, book seats, and make payments through this system.",
      imgurl: "images/portfolio/project2.png",
    },
  ],
  testimonials: [
    {
      description: "This is a sample testimonial",
      name: "Some technical guy",
    },
    {
      description: "This is a sample testimonial",
      name: "Some technical guy",
    },
  ],
};

export default resumeData;
