import React, { Component } from "react";
export default class ContactUs extends Component {
  render() {
    // let resumeData = this.props.resumeData;
    return (
      <section id="contact">
        {/* <div className="row section-head">
            <div className="ten columns">
              <p className="lead">
              Feel free to contact me for any work or suggestions below
              </p>
            </div>
          </div>
          <div className="row">
            <aside className="eigth columns footer-widgets">
              <div className="widget">
                <h4>Linked in :
                  {resumeData.linkedinId}
                </h4>
              </div>
            </aside>
          </div> */}
        <form action="https://formspree.io/f/xayrqllw" method="POST">
          <p className="contact-title">CONTACT ME</p>
          <div className="contact-main-div">
            <div className="contact-form-div">
              <label>
                <input
                  className="label-input"
                  type="text"
                  name="firstname"
                  placeholder=""
                  required=""
                />
                <span>Firstname</span>
              </label>

              <label>
                <input
                  className="label-input"
                  type="text"
                  name="lastname"
                  placeholder=""
                  required=""
                />
                <span>Lastname</span>
              </label>
            </div>

            <label>
              <input
                className="email-input"
                type="email"
                name="email"
                placeholder=""
                required=""
              />
              <span>Email</span>
            </label>
            <label>
              <textarea
                name="message"
                rows="15"
                style={{ width: "100%", resize: "none" }}
              ></textarea>
              <span>Message</span>
            </label>
          </div>

          <button className="contact-form-btn">Submit</button>
        </form>
      </section>
    );
  }
}
