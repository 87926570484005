import React, { Component } from "react";
import Typed from "typed.js";
import res from "../assets/Sujal_Resume_Updated.pdf";
// import ResumeWindow from "./ResumeWindow"
export default class Header extends Component {
  componentDidMount() {
    const headlineElement = document.querySelector(".responsive-headline");

    headlineElement.style.opacity = "0";
    headlineElement.style.transform = "scale(0.8)";
    headlineElement.style.transition = "opacity 1s, transform 1s";

    setTimeout(function () {
      headlineElement.style.opacity = "1";
      headlineElement.style.transform = "translateY(0)";
    }, 400);

    const typed = new Typed(".multiple-text", {
      strings: ["Software Developer", "Web Developer", "Content creator"],
      typeSpeed: 100,
      startDelay: 600,
      backSpeed: 70,
      backDelay: 600,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }
  render() {
    let resumeData = this.props.resumeData;
    // const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);

    // const openResumeModal = () => {
    //   setIsResumeModalOpen(true);
    // };

    // const closeResumeModal = () => {
    //   setIsResumeModalOpen(false);
    // };
    return (
      <React.Fragment>
        <header id="home">
          <nav id="nav-wrap">
            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
              Show navigation
            </a>
            <a className="mobile-btn" href="!#" title="Hide navigation">
              Hide navigation
            </a>
            <ul id="nav" className="nav">
              <li className="current">
                <a className="smoothscroll" href="#home">
                  Home
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#about">
                  About
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#portfolio">
                  Projects
                </a>
              </li>
              <li>
                {/* <a className="smoothscroll" href="">
                  Resume
                </a> */}
                <a href={res} target="_blank" rel="noopener noreferrer">
                  Resume
                </a>
                {/* {isResumeModalOpen && <ResumeWindow onClose={closeResumeModal} />} */}
              </li>
              <li>
                <a className="smoothscroll" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
          </nav>

          <div className="row banner">
            <div className="banner-text">
              <h1 className="responsive-headline">
                Hey, I'm {resumeData.name}.
              </h1>
              <h3>
                <span
                  className="multiple-text"
                  id="multiple-text"
                  style={{
                    fontFamily: "Raleway",
                    fontWeight: "bold",
                    fontSize: "4rem",
                    color: "#0099ff",
                  }}
                />
              </h3>
              <hr />
              <ul className="social">
                {resumeData.socialLinks &&
                  resumeData.socialLinks.map((item) => {
                    return (
                      <li key={item.name}>
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className={item.className} />
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>

          <p className="scrolldown">
            <a className="smoothscroll" href="#about">
              <i className="icon-down-circle" />
            </a>
          </p>
        </header>
      </React.Fragment>
    );
  }
}
